import React, { useState } from "react";
import { Grid, Flex, Button } from "theme-ui";
import { trackButtonCta } from "./analytics";
import Routes from "../constants/routes";
/** @jsx jsx */
import { jsx } from "theme-ui";
import { scrollToElm } from "./dom";
import { CalendlyButton } from "../components/form-calendly";
import { BereavementContact, FormTypes } from "./form-contact";

export const FaqsSection = ({ sectionClasses, pwyc, hideCalendly }) => {
  const [isShowing, setIsShowing] = useState(false);

  return (
    <section className={sectionClasses || "aw-accent"}>
      <div className="aw-content" sx={{ textAlign: "center" }}>
        {!isShowing && (
          <>
            <h2>Still have questions?</h2>
            <Button
              variant="blueButton"
              onClick={() => {
                trackButtonCta("faqs");
                setIsShowing(true);
                scrollToElm("faqs-section");
              }}
            >
              View FAQs
            </Button>
          </>
        )}
      </div>
      {isShowing && <Faqs pwyc={pwyc} hideCalendly={hideCalendly} />}
    </section>
  );
};

export const VIRTUAL_SERVICE_QUESTIONS = [
  {
    question:
      "You hosted a service for us in the past. How long will our Memorial Page be live?",
    answer:
      "We host the Memorial Page forever, or until you request to unpublish it. We do not charge extra for hosting—it's hosted forever for free.",
  },
  {
    question: "What if I have more questions for my planner or videographer?",
    answer:
      "Once we connect you to them, they'll provide more information and answer your questions during their first call.",
  },
];

export const BEREAVEMENT_GUIDE_QUESTIONS = [
  {
    question: "How much does this cost? Is it really free?",
    answer:
      "Yes, it's really free. Afterword is completely free to families and healthcare providers.",
  },
  {
    question: "We already have bereavement resources. How is this better?",
    answer:
      "We're constantly adding to our database of grief resources and ensuring they're up-to-date. We provide funeral education and detailed recommendations based on their unique situation. Everything we provide is translated to multiple languages and accessible digitally on all devices.",
  },
  {
    question: "Can we include the resources from our own bereavement packet?",
    answer:
      "Of course! We'll take your current bereavement resources and add them to Afterword for your patient population. We'll ensure they're up-to-date and translated. ",
  },
  {
    question: "What languages do you translate to?",
    answer:
      "We're currently available in English, Spanish, and Chinese. If you are looking to offer your resources in an additional language, please let us know.",
  },
  {
    question: "How do I share this families?",
    answer: `You can share Afterword by sending them the link or QR code over email or text.`,
  },
  {
    question: "Do I need to download an app?",
    answer:
      "No. Everyone can access the site from whatever device they have—their phone, laptop, or tablet. The site will look and feel like an app, but no download is required.",
  },
  {
    question:
      "Do you have specific resources catered to certain groups? (e.g. LGBTQ, COVID, cancer, suicide, addiction, etc)",
    answer:
      "Yes, we aggregate the resources available in a given location. If your region doesn't have local resources catered to a particular group, we can recommend relevant regional or national groups.",
  },
  {
    question: "How safe is this? What do you do with the data?",
    answer:
      "We follow industry-standard security protocols to protect our technology. We know this is a private moment and aren't collecting any personal information. We will never share or sell any private information.",
  },
  {
    question: "How does your company make money?",
    answer:
      "We make money by charging funeral homes and other vendors for premium features. We never prioritize or recommend vendors based on these payments, and none of our costs fall on the families or hospitals.",
  },
];

export const WHAT_NOW_QUESTIONS = [
  {
    question:
      "How much does your funeral planning service cost? Is it really free?",
    answer:
      "Yes, Afterword's funeral planning service is completely free for families. Our services are free through the support of thousands of funeral homes that work with us.",
  },
  {
    question:
      "I've never planned a funeral before and have a lot of questions. Can I call you?",
    answer: (
      <>
        Yes, you can call or text us at{" "}
        <a href="tel:+18552811851" target="_blank">
          (855) 281-1851
        </a>
        .
      </>
    ),
  },
  // {
  //   question: "I've already made funeral plans. Can you still help?",
  //   answer:
  //     "We can't offer more assistance if you've already selected a funeral home. We can still make recommendations on grief resources when you're ready.",
  // },
  {
    question: "Can funeral homes pay to rank higher in the recommendations?",
    answer: "No, funeral homes do not pay us to recommend them.",
  },
  // {
  //   question: "What languages do you translate to?",
  //   answer:
  //     "We're currently available in English, Spanish, and Chinese. If you are looking to offer your resources in an additional language, please let us know.",
  // },
  {
    question: "Can I get your help without speaking to someone?",
    answer: (
      <>
        Absolutely. We've created an online tool to guide you through the
        process. You can sign up for it{" "}
        <a
          href="#on-your-own"
          onClick={(event) => {
            event.preventDefault();
            scrollToElm("on-your-own");
          }}
        >
          here
        </a>
        .
      </>
    ),
  },
  {
    question: "Do I need to download an app?",
    answer:
      "Nope. You can access the site from whatever device you your phone, laptop, or tablet. The site will look and feel like an app, but no app is required.",
  },
  {
    question:
      "Do you have specific grief resources catered to certain groups? (e.g. LGBTQ, COVID, cancer, suicide, addiction, etc)",
    answer:
      "Yes, we aggregate the resources available in a given location. If your region doesn't have local resources catered to a particular group, we can recommend relevant regional or national groups.",
  },
  {
    question: "What do you do with the data? How safe is working with you?",
    answer:
      "We follow industry-standard security protocols to protect our technology. We know this is a private moment and aren't collecting any personal information. We will never share or sell any private information.",
  },
  {
    question: "How does your company make money?",
    answer:
      "We make money by charging funeral homes and other vendors for premium features. We never prioritize or recommend vendors based on these payments.",
  },
];

const FaqEntry = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      sx={{
        margin: "0 auto",
        maxWidth: "680px",
        paddingTop: "28px",
        paddingBottom: "20px",
        borderTop: !props.isFirst ? "1px solid rgba(0, 0, 0, 0.35)" : "",
      }}
    >
      <div
        sx={{
          display: "flex",
          fontWeight: 600,
          fontSize: "18px",
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div>{props.question}</div>
        <div sx={{ flexGrow: 1 }}></div>
        <div>{isOpen ? "-" : "+"}</div>
      </div>
      {isOpen && <div sx={{ marginTop: "20px" }}>{props.answer}</div>}
    </div>
  );
};

export const Faqs = (props) => {
  const { faqsTitle, hideCalendly, questions, pwyc, variant, faqsBlurb } =
    props;
  const questionsData = questions || VIRTUAL_SERVICE_QUESTIONS;

  let variantStyle = variant || "grid";

  return (
    <section id={props.id || "faqs-section"}>
      <div className="aw-content">
        <h2 sx={{ textAlign: variantStyle === "grid" ? "left" : "center" }}>
          {faqsTitle || "Frequently Asked Questions"}
        </h2>

        {variantStyle === "grid" && (
          <Grid gap={2} columns={["1fr", "1fr 1fr"]}>
            {questionsData.map((item, idx) => {
              let styles = { flexDirection: "column" };
              if (item.fullWidth) {
                styles["gridColumn"] = ["initial", "1 / 3"];
              }
              return (
                <Flex sx={styles}>
                  <h3>{item.question}</h3>
                  {pwyc && <p>{item.pwyc || item.answer}</p>}
                  {!pwyc && <>{item.answer}</>}
                </Flex>
              );
            })}
          </Grid>
        )}

        {variantStyle === "accordion" && (
          <>
            {questionsData.map((item, idx) => {
              return (
                <FaqEntry
                  key={item.question}
                  isFirst={idx === 0}
                  question={item.question}
                  answer={item.answer}
                />
              );
            })}
          </>
        )}

        {variantStyle === "sideAccordion" && (
          <Grid
            gap={2}
            columns={["1fr", "1fr 2fr"]}
            sx={{ position: "relative" }}
          >
            <div sx={{ position: "sticky", top: 100, height: 100 }}>
              <h2 sx={{ textAlign: "left" }}>
                {faqsTitle || "Frequently Asked Questions"}
              </h2>

              <p>{faqsBlurb || ""}</p>
              <BereavementContact formType={FormTypes.FUNERAL_HOMES} />
            </div>

            <div>
              {questionsData.map((item, idx) => {
                return (
                  <FaqEntry
                    key={item.question}
                    isFirst={idx === 0}
                    question={item.question}
                    answer={item.answer}
                  />
                );
              })}
            </div>
          </Grid>
        )}

        {!hideCalendly && (
          <div sx={{ textAlign: "center", padding: "45px 0" }}>
            <h2>
              Still have questions? Schedule a free 30 minute consultation
            </h2>
            <CalendlyButton
              buttonVariant={"blueButton"}
              buttonOverride={"Schedule Consultation"}
            />
          </div>
        )}
      </div>
    </section>
  );
};
