import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout-main";
import Img from "gatsby-image";

/** @jsx jsx */
import { jsx, Grid } from "theme-ui";
import { Helmet } from "react-helmet";

export const EffiesStory = ({ data, title, blurbs }) => (
  <section id="aboutus" className="aw-accent">
    <div className="aw-content">
      <Grid columns={["1fr", "2fr 5fr"]} gap={4} sx={{ alignItems: "center" }}>
        <div>
          <h4
            sx={{
              fontWeight: 500,
              fontSize: "24px",
              textAlign: "center",
              display: ["inherit", "none"],
            }}
          >
            {title || "Effie's story"}
          </h4>
          <div
            sx={{
              margin: "0 auto",
              width: ["100%", "100%"],
              textAlign: "center",
            }}
          >
            <img
              src="/images/headshot-effie.png"
              title="Effie Anolik"
              alt="Effie Anolik"
              sx={{ borderRadius: "4px", width: "100%", height: "auto" }}
            />
          </div>

          <Grid columns={["1fr 1fr"]} gap={"24px"} sx={{ marginTop: "24px" }}>
            <Img
              title="Effie Anolik"
              alt="illustration of Effie Anolik"
              fluid={data.effie1.childImageSharp.fluid}
              sx={{ borderRadius: "4px" }}
            />

            <Img
              title="Effie Anolik"
              alt="illustration of Effie Anolik"
              fluid={data.effie2.childImageSharp.fluid}
              sx={{ borderRadius: "4px" }}
            />
          </Grid>
        </div>

        <div>
          <h4
            sx={{
              fontWeight: 500,
              fontSize: "24px",
              textAlign: "center",
              display: ["none", "inherit"],
            }}
          >
            {title || "Effie's story"}
          </h4>

          {blurbs &&
            blurbs.map((blurb) => {
              return <p sx={{ fontSize: "20px" }}>{blurb}</p>;
            })}

          {!blurbs && (
            <>
              <p sx={{ fontSize: "20px" }}>
                I started Afterword after my dad died in August 2018. Never
                having planned a funeral before, my first instinct was to try to
                plan his service in the way that felt most comfortable to me –
                online. While I didn't grow up above a funeral home, Afterword
                is, in essence, a family business. My dad is the reason it
                exists, and the work we do is personal.
              </p>
              <p sx={{ fontSize: "20px" }}>
                We know your work is personal, too. I’ve gained a deep
                appreciation for the crucial role that funeral directors play in
                the communities they serve. It’s because of this that we know
                software can never replace them. Regardless of age or
                generation, there will always be families who want to plan
                in-person. But there are also those, like me, who are more
                comfortable making these difficult decisions online. With this
                in mind, we've built the planning experience I wish I had years
                ago.
              </p>
            </>
          )}
        </div>
      </Grid>
    </div>
  </section>
);

const AboutPage = ({ data, location }) => {
  return (
    <Layout location={location} pageTitle="About Us">
      <EffiesStory data={data} />
      <section>
        <div className="aw-content">
          <h4 sx={{ fontWeight: 500, fontSize: "24px", textAlign: "center" }}>
            Empathy Meets Innovation
          </h4>

          <p sx={{ fontSize: "20px" }}>
            Before Afterword our team worked at some of the biggest tech
            companies like Google, Shopify, and Workday. We took our technical
            backgrounds and moved into a funeral home to learn as much as we
            could about how you serve your communities. We sat in arrangements,
            listened to first calls, attended removals, watched embalmings, and
            observed as you reentered the decedent's name into all of your
            systems. Similar to funeral service, everything we build has the
            family’s best interest at heart. We know that together we can blend
            tradition with innovation to meet the needs of today’s funeral
            planners.
          </p>

          <Grid gap={[4, 5]} columns={["none", "1fr 1fr"]}>
            <div>
              <div
                sx={{
                  margin: "0 auto",
                  width: ["250px", "200px", "250px", "300px"],
                  textAlign: "center",
                }}
              >
                <Img
                  title="Effie Anolik"
                  alt="illustration of Effie Anolik"
                  fluid={data.effieImage.childImageSharp.fluid}
                />
                <p>
                  <b>Effie Anolik</b>
                </p>
              </div>
              <p>
                Effie's Memorial Moment: Dinner at Cafe Polonez where she and
                her dad ate every week and then getting a scoop of his favorite
                chocolate ice cream, of course.
              </p>
            </div>
            <div>
              <div
                sx={{
                  margin: "0 auto",
                  width: ["250px", "200px", "250px", "300px"],
                  textAlign: "center",
                }}
              >
                <Img
                  title="Zack Moy"
                  alt="illustration of Zack Moy"
                  fluid={data.zackImage.childImageSharp.fluid}
                />
                <p>
                  <b>Zack Moy</b>
                </p>
              </div>
              <p>
                Zack’s Memorial Moment: A cup of coffee (which he always swore
                he would never drink) with a sprinkle of cinnamon, just how his
                mentor liked it.
              </p>
            </div>
          </Grid>
        </div>
      </section>
    </Layout>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query {
    effieImage: file(relativePath: { regex: "/about-effie.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    zackImage: file(relativePath: { regex: "/about-zack.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    effiePhoto: file(relativePath: { regex: "/fe3.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    effie1: file(relativePath: { regex: "/effie1.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    effie2: file(relativePath: { regex: "/effie2.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
